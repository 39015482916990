import React from 'react';

import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import Banner from '../components/banner/Banner';

const BannerTemplate = () => {
  const data = useStaticQuery(GET_BANNER);

  return (
    <Banner
      primaryText={data.markdownRemark.frontmatter.text.primary}
      secondaryText={data.markdownRemark.frontmatter.text.secondary}
    />
  );
};

const GET_BANNER = graphql`
  query getBannerQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "banner" } }) {
      frontmatter {
        text {
          primary
          secondary
        }
      }
    }
  }
`;
export default BannerTemplate;
