import React from 'react';
import styles from './Banner.module.scss';
import NotificationSvg from '../../img/notification.inline.svg';

export default function Banner({ primaryText, secondaryText }) {
  return (
    <div className={styles.container} id="banner">
      <NotificationSvg />
      <span className={styles.primaryText}>{primaryText}</span>
      <span className={styles.secondaryText}>{secondaryText}</span>
    </div>
  );
}
